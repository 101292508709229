@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');


.navbar-div1{
    background-color: transparent !important;
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
    z-index: 1111;
}

.nav-ul1{
    column-gap: 20px;
    margin-right: 50px;
}

.nav-text1{
color: #616161;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.nav-text1:hover{
    color: black;
}

.dark-nav-text1{
    color: #DADADA !important;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.nav-mainbtns-div1{
    column-gap: 15px;
}

.nav-btn-1{
    display: inline-flex;
padding: 15px 30px;
justify-content: center;
align-items: center;
/* gap: 10px; */
border: 1px solid #000;
color: #1A1A1A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.dark-nav-btn-1{
    display: inline-flex;
padding: 15px 30px;
justify-content: center;
align-items: center;
/* gap: 10px; */
border: 1px solid #FFF;
color: #FCFCFC;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.nav-btn-2{
    display: inline-flex;
padding: 15px 30px;
justify-content: center;
align-items: center;
/* gap: 10px; */
background: linear-gradient(89deg, #25B6AC 0.36%, #52EADF 99.56%);
color: #1A1A1A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}





.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border:none;
    box-shadow: 1px 1px 15px #00000047;
    border-radius: .25rem;
}





.dropdown-toggle::after {
    display: none !important;
   
}

.nav-calculate-app-div1{
    background: #F1F1F1;
    padding: 12px;
    align-items: center;
    position: relative;
    width: 230px;
}

.dropdown-item {
    padding: .2rem .7rem;
    background-color: transparent;
    border: none;
}

.dropdown-item:hover{
    background-color: transparent !important;
}

.nav-dropdown-div1 {
    margin-top: 10px;
}








@media screen and (max-width: 1300px) {
    
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    border-radius: .25rem;
    transform: translateX(-50%);
    margin-top: 15px;
}

}

@media screen and (max-width: 1220px) {
    
.nav-calculate-app-div1{
    background: #F1F1F1;
    padding: 12px;
    align-items: center;
    position: relative;
    width: 210px;
}

}


@media screen and (max-width: 1199px) {

.navbar-div1{
    padding-left: 40px;
    padding-right: 40px;
}

.nav-ul1{
    column-gap: 20px;
    margin-right: 30px;
}

}






















.mobile-navbar-div1 {
    background-color: transparent !important;
    min-height: 90px;
    width: 100%;
    position: absolute;
    z-index: 999;
}

@media screen and (max-width: 991px) {
    /* .navbar-div1 {
        display: none;
    } */

    .mobile-navbar-div1 {
        display: block;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 9;
    }
}

.mobile-nav-toogler {
    border: none;
}

/* .mobile-nav-item .nav-link {
     color: #FFF !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin-top: 15px;
    margin-left: 0px !important;
}

.mobile-nav-item .nav-link:hover {
     color: #FEAB02 !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
} */

.mobile-navbar-div2 {
    /* background: #EAEFFF; */
    background-color: #FFF;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 250px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 88px;
    transform: translateX(-110%);
    transition: all 0.5s linear;
    /* box-shadow: 1px 1px 10px rgb(131, 131, 131); */
}

.mobile-navbar-div2.show {
    /* background: #EAEFFF; */
    background-color: #FFF;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    width: 250px;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 90px;
    transition: none;
    transform: translateX(0%) !important;
    transition: all 0.5s linear;
}

.mobile-nav-item.active .nav-link {
    color: black !important;
}


@media screen and (max-width: 576px) {

.nav-text1, .dark-nav-text1{

font-size: 17px;
}

.dark-three-line-svg1 rect{
fill: #FFF !important;
}


}


