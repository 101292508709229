.footer-div1 {
  padding-top: 35px;
  padding-bottom: 65px;
  position: relative;
}

.footer-logo-div1 {
  width: 113px;
  height: 113px;
  flex-shrink: 0;
  background: linear-gradient(135deg, #5e58db 0%, #27219a 100%);
  box-shadow: 0px 0px 44px 0px rgba(118, 118, 118, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-div1 {
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  background: #1877f2;
  box-shadow: 0px 4px 14px 0px rgba(181, 181, 181, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-div2 {
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  background: linear-gradient(
    135deg,
    #4a63c8 0%,
    #c33b98 43.23%,
    #f34f50 74.48%,
    #fec64d 100%
  );
  box-shadow: 0px 4px 14px 0px rgba(181, 181, 181, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}


.footer-services-text1{
color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.footer-services-li1{
color: #3F3F3F;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 34px; /* 212.5% */
}

.footer-services-li1 a{
    color: #3F3F3F;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 34px; /* 212.5% */
}



.footer-contact-text1{
    color: #1A1A1A;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: 10px;
margin-bottom: 4px !important;
}

.footer-copyright-div1{
height: 46px;
flex-shrink: 0;
background: #D9D9D9;
display: flex;
align-items: center;
}

.footer-copy-text12{
    text-align: center;
    margin-bottom: 0px;
    color: #5F5F5F;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}




@media screen and (max-width: 576px) {
  .footer-logo-div1 {
    width: 95px;
    height: 95px;
}
.footer-copyright-div1{
height: 66px;
}
}