.about-banner-div1{
      /* background-color: white; */
      background: linear-gradient(170deg, #FAFAFF 6.97%, #FFF 56.02%, #F6F7FF 95.71%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-top: 55px;
}

.about-banner-text1{
color: #000;
font-family: Poppins;
font-size: 35px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
}

.about-banner-text2{
color: #000;
font-family: Poppins;
font-size: 80px;
font-style: normal;
font-weight: 700;
line-height: 90px; /* 112.5% */
text-transform: uppercase;
}

.about-banner-text2 span{
color: #25B6AC;
font-family: Poppins;
font-size: 80px;
font-style: normal;
font-weight: 700;
line-height: 90px;
text-transform: uppercase;
}

.about-banner-text3{
color: #2C2C2C;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 38px; /* 190% */
margin-top: 10px;
}

.about-banner-btn-div1{
margin-top: 35px;
}



/* section no  2 ##### */

.about-pic-div1{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}

.about-pic-btn-div1{
width: 220px;
height: 220px;
flex-shrink: 0;
position: absolute;

}

.about-pic-btn-text1{
    /* display: flex;
    transform: rotate(30deg);
flex-direction: column;
justify-content: center;
flex-shrink: 0; */
height: 100px;
  width: 100px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%; /* may require vendor prefixes */
color: #FDFDFD;
font-family: Rubik;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 33.6px */
text-transform: uppercase;
}



/* section no 3 ###### */

.account-sec3-div1{
padding-top: 80px;
}

.account-sec3-text1{
color: #000;
font-family: Poppins;
font-size: 46px;
font-style: normal;
font-weight: 700;
line-height: 70px; /* 152.174% */
text-transform: uppercase;
}

.account-sec3-text2{
    color: #2C2C2C;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 38px; /* 190% */
margin-top: 30px;
}





@media screen and (max-width: 576px) {
    .about-banner-div1 {
    padding-top: 0px;
    min-height: 100vh;
}
    .about-banner-text1 {
    font-size: 20px;
}
.about-banner-text2 {
    font-size: 40px;
    line-height:1.25;
}
.about-banner-text2 span{
    font-size: 40px;
    line-height:1.25;
}
.about-banner-text3 {
    font-size: 16px;
    line-height:2;
}

.about-pic-div1 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.account-sec3-text1 {
    font-size: 26px;
    line-height: 44px;
}
.account-sec3-text2 {
    font-size: 16px;
    line-height: 34px;
}
.account-sec3-div1 {
    padding-top: 50px;
}
.about-pic-btn-div1 {
    width: 110px;
    height: 110px;
    flex-shrink: 0;
    position: absolute;
}
}