@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');


a{
    text-decoration: none !important;
}

.home-banner-div1{
   background: linear-gradient(170deg, #FAFAFF 6.97%, #FFF 56.02%, #F6F7FF 95.71%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-top: 90px;
}

.home-banner-text1{
color: #000;
font-family: Poppins;
font-size: 35px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
}

.home-banner-text2{
color: #000;
font-family: Poppins;
font-size: 80px;
font-style: normal;
font-weight: 700;
line-height: 90px; /* 112.5% */
text-transform: uppercase;
}

.home-banner-text2 span{
background: linear-gradient(180deg, #48FFF2 0%, #0D9F95 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Poppins;
font-size: 80px;
font-style: normal;
font-weight: 700;
line-height: 90px;
text-transform: uppercase;
}

.home-banner-text3{
    color: #2C2C2C;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 1.3;
margin-left: 12px;
}

.banner-btn-div1{
margin-top: 25px;
position: relative;
}

.banner-btn-1{
    width: 290px;
/* height: 63px; */
   padding-top: 15px;
   padding-bottom: 15px;
   padding-left: 25px;
   /* padding-right: 15px; */
   background-color: #000;
/* flex-shrink: 0; */
color: #FFF;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
display: flex;
align-items: center;
}

.home-calculate-app-div1{
    background: #F1F1F1;
    padding: 12px;
    align-items: center;
    position: relative;
    min-width: 267px;
}

.home-dropdown-div1{
    
    position: absolute;
    transform: translate3d(0px, 0px, 0px) !important;
    top: 70px !important;
    left: 0px;
    will-change: transform;
}



/* section ################ 2 */

.acc-sec-div2{
background: linear-gradient(97deg, #3035E3 0%, #3136FE 101.68%);
padding-top: 30px;
padding-bottom: 25px;
position: relative;

}

.tax-sec-div2{
background: linear-gradient(260deg, #282828 0%, #000 73.8%);
padding-top: 30px;
padding-bottom: 25px;
position: relative;
}

.acc-svg-1{
    position: absolute;
    right: 0px;

}

.acc-svg-2{
  position: absolute;
    right: 0px;
}

.acc-sec-div3{
    column-gap: 70px;
}


.acc-text1{
color: #FFF;
font-family: Inter;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.acc-text2{
    color: #EDEDED;
font-family: Poppins;
font-size: 20px;
font-style: normal;
line-height: 30px; /* 190% */
}

.acc-text2:hover{
    color: #EDEDED;
    text-decoration: none;
}



/* section #### 3 */

.pro-sec-div1{
    padding-top: 100px;
    padding-bottom: 100px;
}

.pro-col-div2{
    padding-right: 40px;
}

.pro-sec-text1{
color: #000;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.pro-sec-text2{
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */

}

.pro-sec-btn1-div1{
    margin-top: 20px;
    display: inline-flex;
    padding: 20px 50px;
justify-content: center;
align-items: center;

border: 1px solid #000;
color: #1A1A1A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.pro-col-div3{
padding-left: 40px;
}

.pro-card-div1{
    padding-left: 13px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
    background: #F5F5F5;
    column-gap: 25px;
}

.profile-img-div1{
    position: relative;
    display: flex;
    justify-content: center;
}

.profile-watch-div1{
position:absolute;
bottom: 10px;
background: rgba(49, 55, 242, 0.70);
backdrop-filter: blur(7px);
width: 134px;
height: 32px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
}

.pro-watch-text1{
    color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 3px !important;

}

.pro-card-para-div1{
padding-top: 5px;
padding-bottom: 5px;
}

.pro-text-para1{
color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.stars-div11{
    column-gap: 5px;
}

.pro-text-head1{
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 10px;
}

.pro-text-head1 span{
color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}


.profile-watch-div2{
position:absolute;
bottom: 10px;
background: rgba(39, 184, 174, 0.70);
backdrop-filter: blur(7px);
width: 134px;
height: 32px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
}

.profile-watch-div3{
position:absolute;
bottom: 10px;
background: rgba(255, 196, 33, 0.90);
backdrop-filter: blur(7px);
width: 134px;
height: 32px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
}

.pro-watch-text12{
    color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 3px !important;

}


/* section no ###### 4 */

.works-sec4-div1{
position: relative;
padding-top: 120px;
padding-bottom: 100px;
background: #1C1C1C;
}

.work-sec-svg-7{
    position: absolute;
    top: 0px;
    left: -20px;
}

.work-btn-div1 {
position: absolute;
right: 10%;
bottom: 33%;
z-index: 888;
}

.work-btn-1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
height: 57px;
transform: rotate(8deg);
flex-shrink: 0;
/* border: 1px solid #7D7D7D; */
background: #1AB7AC;
box-shadow: 0px 24px 34px 0px rgba(73, 73, 73, 0.25);
color: #FFF;
font-family: Inter;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
animation-name: updownanimation;
animation-duration: 5s;
animation-iteration-count: infinite;
animation-iteration-function: ease;
transition: all .5s;
}

@keyframes updownanimation{
    0%{
        transform: translatey(-30px);
    }
    50%{
        transform: translatey(30px);
    }
     100%{
        transform: translatey(-30px);
    }
}

.works-sec4-text1 span{
color: #22B7AD;
font-family: Poppins;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 1.2;
}

.works-sec4-text1{
color: #FFF;
font-family: Poppins;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 1.2;
}

.works-sec4-text2{
    color: #D1D1D1;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
}



.num-box-div1{
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.num-box-text1{
    color: #FFF;
font-family: Poppins;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 1;
margin-bottom: 0px;
position: absolute;

}

.num-box-div2{
    margin-left: 35px;
}

.num-box-text2{
color: #FFF;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.num-box-text3{
color: #D1D1D1;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
margin-bottom: 8px;
}

.num-box-div00{
    margin-top: 70px;
}

.mobile-img{
    width: 630px;
    margin-top: 15px;
}

.num-box-div0{
    margin-top: 35px;
}















@media screen and (max-width: 1330px) {
    .mobile-img {
    width: 550px;
}
.acc-sec-div3 {
    column-gap: 20px;
}
.home-banner-text2 {
    font-size: 64px;
line-height: 80px; /* 112.5% */
}
.home-banner-text2 span{
    font-size: 64px;
line-height: 80px; /* 112.5% */
}
}

@media screen and (max-width: 1199px) {
    .mobile-img {
    width: 500px;
}
.home-banner-text2 {
    font-size: 60px;
line-height: 78px; /* 112.5% */


}
.home-banner-text2 span{
    font-size: 60px;
line-height: 78px; /* 112.5% */


}
}






@media screen and (max-width: 576px) {
    .home-banner-div1{
    padding-top: 120px;
    padding-bottom: 55px;
}
    .home-banner-text1 {
    font-size: 24px;
    line-height: 1;
}
.home-banner-text2 {
       font-size: 44px;
line-height: 1.2;
}
.home-banner-text2 span{
       font-size: 48px;
    line-height: 1.2;
}
.home-banner-text3 {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0px;
    
}
.mobile-responsive-div1{
    align-items: center;
    margin-bottom: 12px;
}
.for-responsive-banner-btn-div1{
    min-width: 100%;
    padding-left: 22px !important;
    padding-right: 22px !important;
    font-size: 20px;
justify-content: space-between;
}

.home-banner-div3{
    margin-top: 55px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    border-radius: .25rem;
    transform: translateX(-50%);
    margin-top: 7px;
    min-width: 100%;
}







.tax-sec-div1{
    overflow:hidden;
    padding-left:25px;
    padding-right:25px;
}
.acc-sec-div3{
    column-gap: 33px;
}
.acc-sec-div3 div img{
width:90px;
height: 90px;
}
.acc-text1 {
    font-size: 18px;
    line-height: normal;
}
.acc-text2 {
    font-size: 15px;
}
.acc-text2 svg{
    width: 10px;
    height: 10px;
}
.acc-svg-1 {
    width: 35px;
    height: 180.873px;
    top: -20px;
    right: -1px;
}
.acc-svg-2 {
    width: 80.191px;
    height: 130.977px;
    right: -30px;
    top: 10px;
}
.acc-sec-div2, .tax-sec-div2{
    overflow: hidden;
    padding-top: 23px;
    padding-bottom: 20px;
}
.tax-sec-div2{
    margin-top: 20px;
}









.pro-sec-div1 {
    padding-top: 60px;
    padding-bottom: 60px;
}
.pro-sec-text1 {
    font-size: 26px;
    line-height: normal;
}
.pro-sec-text2 {
    font-size: 14px;
}
.pro-col-div2 {
    padding-right: 15px;
}
.pro-sec-btn1-div1 {
    margin-top: 20px;
    padding: 18px 50px;
    width:100% !important;
    font-size: 14px;
}

.pro-col-div3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-top:50px;
}
.profile-img-div1{
    width:132px;
    height:132px;
}
.pro-text-para1 {

    font-size: 13px;
}
.pro-card-div1 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
    background: #F5F5F5;
    column-gap: 13px;
}
.stars-div11 svg{
    width: 15px;
    height: 15px;
    margin-top: -8px;
}
.pro-text-head1 span {
    font-size: 14px;
}
.pro-text-head1{
    font-size: 14px;
    margin-top: 6px;
}
.pro-card-para-div1 {
    padding-top: 0px;
    padding-bottom: 0px;
}
.profile-watch-div3, .profile-watch-div1, .profile-watch-div2 {
    width: 120px;
}

.pro-watch-text1 svg{
   margin-top:-1px;
}
.pro-watch-text12 svg{
   margin-top:-1px;
}

.works-sec4-div1 {
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;
}

.mobile-img{
    width:320px;
    height: 320px;
}

.work-btn-1{
    width: 230px;
height: 50px;
transform: rotate(8deg);
font-size: 18px;
}
.works-sec4-text1 span{
    margin-top:30px;
    font-size: 32px;
}
.works-sec4-text1{
    margin-top:30px;
    font-size: 32px;
}
.works-sec4-text2 {
    font-size: 14px;
}
.num-box-text2 {
    font-size: 20px;
}
.num-box-text3 {
    font-size: 14px;
}
.num-box-text1 {
    font-size: 28px;
}
.num-box-div1 svg{
width: 60px;
height:58px;
}
.num-box-div00 {
    margin-top: 30px;
}

}






