

.calculate-section1-div1{
min-height: 100vh;
background: linear-gradient(170deg, #1D1D1D 6.97%, #1A1A1A 56.02%, #000 95.71%);
display: flex;
align-items: center;
justify-content: center;
position: relative;
padding-top: 180px;
padding-bottom: 100px;
overflow: hidden;
overflow: hidden;
width: 100%;
}

.line-svg-8{
    position: absolute;
    left: -10px;
    top: 35%;
}
.line-svg-9{
    position: absolute;
    right: -10px;
    bottom: 2%;
}

.calculate-section1-div2{
    padding-left: 60px;
    padding-right: 60px;
}

.calculate-section1-text1{
color: #50E7DC;
font-family: Poppins;
font-size: 80px;
font-style: normal;
font-weight: 700;
line-height: 90px; /* 112.5% */
text-transform: uppercase;
text-align: center;
}

.calculate-section1-text1 span{
color: #FFBC49;
font-family: Poppins;
font-size: 80px;
font-style: normal;
font-weight: 700;
line-height: 90px;
text-transform: uppercase;
}

.calculate-section1-text2{
color: #F8F8F8;
text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 38px; /* 190% */
}



.calculate-form-div1{
padding: 40px;
border: 1px solid #000;
background: #FCFCFC;
margin-top: 50px;
position: relative;
z-index: 121;
}

.calculate-input-field-div1{
    display:flex;
    align-items:center;
    height: 50px;
flex-shrink: 0;
border: 1px solid #DCDCDC;
padding-left: 18px;

}

.form-control:focus {
    color: #495057;
background: #FCFCFC;
    border-color: none;
    outline: 0;
    box-shadow: none !important;
}


.calculate-input-field{
    color: #8B8B8B;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 1;
letter-spacing: 0.35px;
background: #FCFCFC;
}

.calculate-input-field{
    border:none;

}

.calculate-btn1{
    /* display: flex; */
/* width: 658px; */
padding-top: 15px;
padding-bottom: 15px;
justify-content: center;
align-items: center;
background: linear-gradient(89deg, #25B6AC 0.36%, #52EADF 99.56%);
color: #1A1A1A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 25px;
border: none;
}


.calculate-or-div1{
margin-top: 35px;
column-gap: 50px;
align-items: center;
justify-content: center;
}

.calculate-or-text1{
color: #5F5E5E;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 1; /* 118.317% */
letter-spacing: -0.152px;
margin-bottom: 0px;
}

.calculate-or-line1{
width: 120px;
height: 1px;
background: #DFDFDF;
}

.calculate-app-maindiv1{
    margin-top: 35px;
}

.calculate-app-div1{
    background: #F1F1F1;
    padding: 13px;
    align-items: center;
    position: relative;
}

.calculate-app-icon-div1{
    padding: 8px;
    background: #FFF;
box-shadow: 0px 4px 14px 0px rgba(181, 181, 181, 0.25);
}

.calculate-app-icon-text1{
    color: #3E3E3E;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 1.6; /* 133.333% */
margin-bottom: 0px;
margin-left: 15px;
}

.arrow-svg1{
    position: absolute;
    right: 25px;
}






@media screen and (max-width: 576px) {
    .calculate-section1-div2 {
    padding-left: 10px;
    padding-right: 10px;
}
.calculate-section1-text2 {
    font-size: 16px;
}
.calculate-form-div1 {
    padding: 30px 20px;
    border: 1px solid #000;
    background: #FCFCFC;
    margin-top: 30px;
    position: relative;
    z-index: 1;
}
.calculate-app-maindiv1 {
   padding: 0px;
}
}